<template>
  <el-select class="counters-select-camera-groups" multiple v-model="model" :placeholder="$tf('not_selected')">
    <el-option v-for="item of items" :key="item.id" :label="$filters.shortString(item.name)" :value="item.id">
      <el-tooltip effect="dark" :content="item.name" placement="top">
        <span>{{ item.name | shortString }}</span>
      </el-tooltip>
    </el-option>
  </el-select>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    value: { type: Array, required: true },
    items: { type: Array, required: true }
  }
})
export default class CountersSelectCameraGroups extends Vue {
  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }
}
</script>

<style lang="stylus">
.counters-select-camera-groups {
  width: 20rem;
}
</style>
