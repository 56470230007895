<template>
  <div class="counters-roi">
    <div v-for="camera in items">
      <div class="counters-roi__camera-link" @click="() => toggleVisible(camera.id)">{{ camera.name }}</div>
      <counter-roi-drawer class="counters-roi__drawer" :camera-id="camera.id" :item="item" v-if="visibleItems[camera.id]"></counter-roi-drawer>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import CounterRoiDrawer from './roi.drawer';

@Component({
  props: {
    item: { type: Object, required: true },
    camerasMap: { type: Object, required: true }
  },
  components: {
    CounterRoiDrawer
  }
})
export default class CounterRoiForm extends Vue {
  visibleItems = {};

  created() {
    this.setVisibleItems();
  }

  setVisibleItems() {
    const totalCameras = this.items.length;
    if (totalCameras === 1) {
      this.visibleItems[this.items[0].id] = true;
    }
  }

  toggleVisible(id) {
    const value = !this.visibleItems[id];
    this.$set(this.visibleItems, id, value);
  }

  get items() {
    return this.item.cameras.map((id) => this.camerasMap[id]);
  }
}
</script>

<style lang="stylus">
.counters-roi {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &__drawer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__camera-link {
    cursor: pointer;
    display: block;
    margin-top: 1em;
    text-decoration: underline;
  }
}
</style>
